require('inputmask/dist/jquery.inputmask.bundle.js');
require('inputmask/dist/inputmask/inputmask.date.extensions.js');
require('inputmask/dist/inputmask/inputmask.numeric.extensions.js');

(function($) {
  $(".cuit-inputmask").inputmask({
    "mask":      "99999999999",
    placeholder: ' ',
  });
})(jQuery)

