console.log('js-datepicker');

$(document).ready(function() {
	if ($('input.js-datepicker').length) {
		$('input.js-datepicker').each(function(index) {
			$(this).datepicker({
					format: 'dd/mm/yyyy',
					autoclose: true,
			});
		});
	}
});