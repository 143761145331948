// Keenthemes' plugins
window.KTUtil                     = require('@metonic/js/components/util.js');
window.KTApp                      = require('@metonic/js/components/app.js');
window.KTCard                     = require('@metonic/js/components/card.js');
window.KTCookie                   = require('@metonic/js/components/cookie.js');
window.KTDialog                   = require('@metonic/js/components/dialog.js');
window.KTHeader                   = require('@metonic/js/components/header.js');
window.KTImageInput               = require('@metonic/js/components/image-input.js');
window.KTMenu                     = require('@metonic/js/components/menu.js');
window.KTOffcanvas                = require('@metonic/js/components/offcanvas.js');
window.KTScrolltop                = require('@metonic/js/components/scrolltop.js');
window.KTToggle                   = require('@metonic/js/components/toggle.js');
window.KTWizard                   = require('@metonic/js/components/wizard.js');
require('@metonic/js/components/datatable/core.datatable.js');
require('@metonic/js/components/datatable/datatable.checkbox.js');
require('@metonic/js/components/datatable/datatable.rtl.js');

// Metronic layout base js
window.KTLayoutAside              = require('@metonic/js/layout/base/aside.js');
window.KTLayoutAsideMenu          = require('@metonic/js/layout/base/aside-menu.js');
window.KTLayoutAsideToggle        = require('@metonic/js/layout/base/aside-toggle.js');
window.KTLayoutBrand              = require('@metonic/js/layout/base/brand.js');
window.KTLayoutContent            = require('@metonic/js/layout/base/content.js');
window.KTLayoutFooter             = require('@metonic/js/layout/base/footer.js');
window.KTLayoutHeader             = require('@metonic/js/layout/base/header.js');
window.KTLayoutHeaderMenu         = require('@metonic/js/layout/base/header-menu.js');
window.KTLayoutHeaderTopbar       = require('@metonic/js/layout/base/header-topbar.js');
window.KTLayoutStickyCard         = require('@metonic/js/layout/base/sticky-card.js');
window.KTLayoutStretchedCard      = require('@metonic/js/layout/base/stretched-card.js');
window.KTLayoutSubheader          = require('@metonic/js/layout/base/subheader.js');

// Metronic layout extended js
window.KTLayoutChat               = require('@metonic/js/layout/extended/chat.js');
window.KTLayoutDemoPanel          = require('@metonic/js/layout/extended/demo-panel.js');
window.KTLayoutExamples           = require('@metonic/js/layout/extended/examples.js');
window.KTLayoutQuickActions       = require('@metonic/js/layout/extended/quick-actions.js');
window.KTLayoutQuickCartPanel     = require('@metonic/js/layout/extended/quick-cart.js');
window.KTLayoutQuickNotifications = require('@metonic/js/layout/extended/quick-notifications.js');
window.KTLayoutQuickPanel         = require('@metonic/js/layout/extended/quick-panel.js');
window.KTLayoutQuickSearch        = require('@metonic/js/layout/extended/quick-search.js');
window.KTLayoutQuickUser          = require('@metonic/js/layout/extended/quick-user.js');
window.KTLayoutScrolltop          = require('@metonic/js/layout/extended/scrolltop.js');
window.KTLayoutSearch             = 
window.KTLayoutSearchOffcanvas    = require('@metonic/js/layout/extended/search.js');

require('@metonic/js/layout/initialize.js');
